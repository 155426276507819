/**
 * Created by Terence on 2023/2/15 - 15:02
 * Description :
 */
import { isDev } from "@/utils/env-related";

export const storageKeys = {
  me: isDev ? "DEV_ME" : "ME",
  token: isDev ? "DEV_TOKEN" : "TOKEN",
  REFRESH_TOKEN: isDev ? "DEV_REFRESH_TOKEN" : "REFRESH_TOKEN",
  TOKEN_EXPIRE_AT: isDev ? "DEV_TOKEN_EXPIRE_AT" : "TOKEN_EXPIRE_AT",
  packageId: "PACKAGE_ID",
  IsViewed360Guide: "IS_VIEWED_360_GUIDE",
  tankardOffset: isDev ? "DEV_TANKARD_OFFSET" : "TANKARD_OFFSET",
  HAS_LOGIN: isDev ? "DEV_HAS_LOGIN" : "HAS_LOGIN",
  USER_INFO: isDev ? "DEV_USER_INFO" : "USER_INFO",
  PAY_TOKEN_INFO: isDev ? "DEV_PAY_TOKEN_INFO" : "PAY_TOKEN_INFO",
};

import { rpApi, rpNft } from "@/http";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { genOSSUpload } from "@frontend/eros/dist/web";
import { isProd } from "@/utils/env-related";

const PATH = `/upload/admin`;

export interface IFile {
  name: string;
  // [key: string]: any
}

export default {
  getTempToken() {
    return rpApi.post("/uploader/sts/query");
  },

  /**
   *
   * @param {File} file
   * @param {String} scene available value see: https://admin.echoing.tech/config/app/oss_upload
   * @returns {
   *  url: {FullUrl},
   *  ossUrl: {ossUrl}
   *
   * }
   */
  async ossUpload(file: IFile, scene = "interior-admin.image") {
    const { data }: any = await this.getTempToken();
    const { policy, OSSAccessKeyId, signature } = data;
    const upload = genOSSUpload({ policy, OSSAccessKeyId, signature, isProd });

    return upload({ file, scene });
  },

  // async uploadFile(file) {
  //   const form = new FormData()
  //   form.append('batch_deliver', file)
  //
  //   return HttpTools.getInstance().goHttp({
  //     url: '/order_support/trade/om_batch_apply_deliver',
  //     method: 'POST',
  //     data: form,
  //   })
  // },
};

export const copyToClipboard = (text: string) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.style.position = "fixed"; // 确保该元素在页面中不可见
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
};

export const shareToQQ = ({
  title,
  desc,
  share_url,
  imgUrl,
}: {
  title: string;
  desc: string;
  share_url: string;
  imgUrl: string;
}) => {
  debugger;
  if (typeof window.mqq === "undefined") {
    alert("请在手机QQ客户端打开此页面");
    return;
  }

  const shareInfo = {
    title, // 分享标题
    desc, // 分享描述
    share_url, // 分享链接
    imgUrl, // 分享图标
  };

  console.log("window.mqq", window.mqq);

  window.mqq.ui.shareMessage({
    title: shareInfo.title,
    desc: shareInfo.desc,
    share_url: shareInfo.share_url,
    imgUrl: shareInfo.imgUrl,
    share_type: 0, // 0: QQ好友，1: QQ空间，2: QQ收藏，3: QQ群
    back: function (res: any) {
      if (res.retCode === 0) {
        alert("分享成功");
      } else {
        alert("分享失败，错误码：" + res.retCode);
      }
    },
  });
};

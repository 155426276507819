/**
 * Created by Terence on 2022/8/23 - 17:24
 * Description :
 */
import _axios from "axios";
import type { AxiosPromise, AxiosRequestConfig } from "axios";
import { showFailToast, closeToast } from "vant";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pigeonInterceptor from "@frontend/pigeon/dist/browser-axios.min.js";
import { commonRequestInterceptor, commonResponseInterceptor } from "./common";

interface IRes {
  code: number;
  data: any;
  message: string;
}

// TODO 测过期时401是否会重定向至login页
// TODO config ts
export function createService<Axios, D = any>(
  config: AxiosRequestConfig,
  headers?: any
) {
  const axios = _axios.create(config);

  // 先使用pigeon拦截器
  axios.interceptors.request.use(pigeonInterceptor);

  // 添加请求拦截器
  axios.interceptors.request.use((request) => {
    return commonRequestInterceptor(request, headers, config);
  });

  // TODO 现有请求request都加上withToken
  // 添加响应拦截器
  axios.interceptors.response.use(
    /*    function (response: any) {
      console.log('response=------', response);
      // 2xx 范围内的状态码都会触发该函数。
      // 对响应数据做点什么
      // console.log("response!", response);
      if (response.data.code !== 0 && checkNeedDeny(response)) {
        console.log("errorMsg", response?.data?.message);
        showFailToast(`${response?.data?.message}`);
      }
      if (allowAclList.includes(response.config.url)) {
        return response
      }
      return response.data;
    },*/
    (request) => {
      return commonResponseInterceptor(request, headers);
    },
    function (error) {
      // 超出 2xx 范围的状态码都会触发该函数。
      // 对响应错误做点什么
      console.log("error", error);
      console.log(
        "error?.response?.data?.message",
        error?.response?.data?.message
      );
      // 只要401, 就重定向至login页
      if (error.response.status === 401) {
        if (!window.location.href.includes("/login")) {
          window.location.href = `${location.origin}/login`;
        } else {
          closeToast();
          showFailToast(error?.response?.data?.message);
        }
      }
      // 发送数据格式有问题. http code 4xx
      // alert("发送数据失败, 请刷新重试");
      // console.log('showFailToast', showFailToast);
      console.log("showFailToast", showFailToast);
      // showFailToast('1234');
      // showFailToast(`${error?.response?.data?.message}`);
      return Promise.reject(error);
    }
  );

  const post = (url: string, data?: D, config?: D): AxiosPromise<IRes> => {
    return axios({
      method: "post",
      url,
      data,
      ...config,
    });
  };

  const get = (url: string, params?: D, config?: D): AxiosPromise<IRes> => {
    return axios({
      method: "get",
      url,
      params,
      ...config,
    });
  };

  const put = (url: string, data?: D, config?: D): AxiosPromise<IRes> => {
    return axios({
      method: "put",
      url,
      data,
      ...config,
    });
  };
  const _delete = (url: string, data?: D, config?: D): AxiosPromise<IRes> => {
    return axios({
      method: "put",
      url,
      data,
      ...config,
    });
  };

  return { post, get, put, delete: _delete, axios };
}

<!--
   Created by Terence on 2022/9/8 - 11:16
-->
<template>
  <div class="trans">
    <van-image
      fit="cover"
      class="head-img"
      src="https://cdn.qiandaoapp.com/interior/images/b777fe43fe035b40b90a10f2d54b917b.jpg!lfit_w1080_h1080_png"
      alt=""
    />
    <img id="uploaded-image" style="display: none" />
    <!--    <main class="content" v-if="!jobInfo">-->

    <!--     TODO 重新穿越清空jobInfo和jobResult-->
    <!-- jobResult -->
    <main class="content" v-if="jobResult">
      <!--          <main class="content" v-if="false">-->
      <!--    <main class="content" v-if="true">-->
      <div class="content-result" v-if="jobResult.isSuccess">
        <!--      <div class="content-result" v-if="true">-->
        <div class="content-result-title--success">穿越成功!</div>
        <div class="content-result-taskid">
          任务编号：<span class="content-result-taskid-value">{{
            searchTaskId
          }}</span>
        </div>
        <div class="content-result-successtips">
          记得长按保存图片噢，避免退出后找不到图片😭
        </div>
        <!--        originImgUrl-->
        <!--        :-->
        <!--        ""-->
        <!--        queuePosition-->
        <!--        :-->
        <!--        0-->
        <!--        realisticImgUrl-->
        <!--        :-->
        <!--        "https://cdn.qiandaoapp.com/recsys/images/sd_webui/7295cbf2-5c9e-4878-b694-7b84ff7a9a99_1.jpg"-->
        <!--        recreateImgUrl-->
        <!--        :-->
        <!--        "https://cdn.qiandaoapp.com/recsys/images/sd_webui/7295cbf2-5c9e-4878-b694-7b84ff7a9a99_0.jpg"-->
        <div class="imglist">
          <div class="imglist-item" v-if="jobResult?.recreateImgUrl">
            <div class="imglist-item-title">
              <div class="imglist-item-title-main">1号穿越机</div>
              <div class="imglist-item-title-sub">更接近原图</div>
            </div>
            <van-image
              fit="cover"
              class="imglist-item-img"
              :src="jobResult.realisticImgUrl + '!lfit_w1080_h1080_png'"
              alt=""
            />
          </div>
          <div class="imglist-item" v-if="jobResult?.realisticImgUrl">
            <div class="imglist-item-title">
              <div class="imglist-item-title-main">2号穿越机</div>
              <div class="imglist-item-title-sub">更接近现实</div>
            </div>
            <van-image
              fit="cover"
              class="imglist-item-img"
              :src="jobResult.recreateImgUrl + '!lfit_w1080_h1080_png'"
              alt=""
            />
          </div>
          <div class="imglist-item" v-if="jobResult?.originImgUrl">
            <div class="imglist-item-title">
              <div class="imglist-item-title-main">原图</div>
            </div>
            <van-image
              fit="cover"
              class="imglist-item-img"
              :src="jobResult.originImgUrl + '!lfit_w1080_h1080_png'"
              alt=""
            />
          </div>
          <div @click="handleShare">
            <DuButton type="primary" size="large" extClass="share-btn"
              >分享穿越机</DuButton
            >
          </div>
          <div @click="handleReset">
            <DuButton type="recommend" size="large" extClass="addqq-btn"
              >返回首页</DuButton
            >
          </div>
          <div @click="handleAddQQ">
            <DuButton type="recommend" size="large" extClass="addqq-btn"
              >加小助手QQ交流</DuButton
            >
          </div>
        </div>
      </div>
      <div class="content-result" v-else>
        <div class="content-result-title--failed">穿越失败</div>
        <div class="content-result-taskid">
          任务编号：<span class="content-result-taskid-value">{{
            jobResult.taskId
          }}</span>
        </div>
        <div class="content-result-failedtips">
          可能由于网络问题或任务难度过高，本次穿越失败了，重新试一次吧~
        </div>

        <div @click="handleRetry">
          <DuButton type="primary" size="large" extClass="retry-btn"
            >重新穿越</DuButton
          >
        </div>
        <div @click="handleAddQQ">
          <DuButton type="recommend" size="large" extClass="addqq-btn"
            >加小助手QQ交流</DuButton
          >
        </div>
      </div>
    </main>
    <main class="content" v-else-if="!jobInfo">
      <!--          <main class="content" v-else-if="false">-->
      <van-uploader :before-read="beforeRead" :after-read="afterRead">
        <div class="uploadwrp" v-if="!uploadData.originUrl">
          <div class="info">
            <div class="info-title">
              <DuIcon
                color="#E1833E"
                name="plus-circle-filled"
                extClass="addicon"
              />
              <span>添加图片</span>
            </div>
            <div class="info-desc">建议选择单人头像或半身像</div>
          </div>
        </div>
        <div class="uploadwrp" v-else>
          <van-image
            fit="cover"
            class="origin-img"
            :src="uploadData.originUrl + '!lfit_w1080_h1080_png'"
            alt=""
          />
        </div>
      </van-uploader>

      <div class="prompt">
        <div class="prompt-title">画面类型</div>
        <div class="prompt-content">
          <van-radio-group
            v-model="uploadData.imageType"
            direction="horizontal"
          >
            <van-radio name="AvatarOrHalfBody" checked-color="#e1833e"
              >头像/半身</van-radio
            >
            <van-radio name="FullBodyOrIllustration" checked-color="#e1833e"
              >全身/插图</van-radio
            >
          </van-radio-group>
        </div>
      </div>

      <div class="prompt">
        <div class="prompt-title">描述图片细节</div>
        <div class="prompt-content">
          <textarea
            v-model="uploadData.prompt"
            class="prompt-content-textarea"
            type="textarea"
            placeholder="使用逗号分割，描述人物数量、性别、外貌、动作特征，比如：一个男孩，红色短卷发，蓝色眼睛，微笑，白色衬衫"
            rows="4"
          />
          <span class="prompt-content-counter"
            >{{ uploadData.prompt.length }}/140</span
          >
        </div>
      </div>

      <div class="btn" @click="handleTrans">
        <DuButton type="primary" size="large" extClass="actionbtn"
          >开始穿越</DuButton
        >
        <div class="searchentry" @click="handleShowTaskPopup">
          查询穿越历史
          <DuIcon color="#918B9F" name="arrow-right" extClass="righticon" />
        </div>
      </div>
    </main>

    <main class="content" v-else>
      <div class="content-stage">
        <div class="content-stage-content">
          <div class="content-stage-content-title">穿越中...</div>
          <div class="content-stage-content-time">
            前面还有 <span>{{ jobInfo?.queuePosition }}</span> 人，预计最多等待
            {{ jobInfo?.waitTime }} 秒
          </div>
        </div>
      </div>
      <div class="content-id">
        <div class="content-id-title">任务编号</div>

        <div class="content-id-value" id="copyflag">
          {{ jobInfo.taskId || searchTaskId }}
        </div>

        <!--          {{ jobInfo.taskId || searchTaskId }}-->

        <!--                <input class="content-id-value" id="copyflag" :value="666"/>-->
      </div>
      <div class="content-copybtn" @click="handleCopy">
        <DuButton type="primary" size="large" extClass="actionbtn"
          >复制编号，稍后查询任务进度</DuButton
        >
        <div @click="handleReset">
          <DuButton type="recommend" size="large" extClass="addqq-btn"
            >返回首页</DuButton
          >
        </div>
      </div>
    </main>

    <div class="tpopup-poster">
      <van-popup
        v-model:show="showBottomActionPopup"
        closeable
        position="center"
        round
      >
        <div class="poster-img">
          <van-image
            v-if="showBottomActionPopup"
            fit="cover"
            class="poster-img-content"
            :src="`${
              hosts.posterWeb
            }/nft-community/get-oc-trans-result-poster?taskId=${
              jobInfo?.taskId || searchTaskId
            }`"
            alt=""
          />
        </div>
        <div class="poster-img-tips">长按保存海报到相册</div>
      </van-popup>
    </div>

    <div class="tpopup">
      <van-popup v-model:show="showTaskPopup" closeable position="center" round>
        <div class="tpopup-title">输入任务编号</div>
        <input class="tpopup-input" type="text" v-model="searchTaskId" />
        <div @click="() => getTransHistory()">
          <DuButton type="primary" size="large" extClass="tpopup-actbtn"
            >查询任务状态</DuButton
          >
        </div>
      </van-popup>
    </div>

    <div class="tpopup">
      <van-popup v-model:show="showQQPopup" closeable position="center" round>
        <div class="tpopup-title">添加小助手QQ加入内测交流群</div>
        <div class="tpopup-qqnumber">来分享你的三次元oc</div>
        <div @click="() => handleCopyQQ()">
          <DuButton type="recommend" size="large" extClass="copyqq-btn"
            >复制QQ号</DuButton
          >
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  Uploader as VanUploader,
  Image as VanImage,
  Popup as VanPopup,
  RadioGroup as VanRadioGroup,
  Radio as VanRadio,
  showFailToast,
  closeToast,
  showSuccessToast,
  showLoadingToast,
} from "vant";
import webShare from "easy-web-share";
import hosts from "@/http/hosts";
import DuIcon from "@frontend/du-icon/src/Icon.vue";
import { DuButton } from "@frontend/dumpling";
import ApiUpload from "@/apis/upload";
import ApiOC from "@/apis";
import { REQUEST_OK } from "@/constant/network";
import { copyToClipboard } from "@/utils/tools";

const QQ_NUMBER = "2657455055";

const shareInfo = {
  // 这里填写您要分享的内容、链接等信息
  title: "OC穿越机",
  url: window.location.href,
  desc: "OC穿越机",
  imgUrl:
    "https://cdn.qiandaoapp.com/interior/images/b777fe43fe035b40b90a10f2d54b917b.jpg!lfit_w1080_h1080_png",
  summary: "OC穿越机",
};

const uploadData = ref({
  originUrl: "",
  imageType: "AvatarOrHalfBody",
  prompt: "",
});

const timer = ref(null);
const showTaskPopup = ref(false);
const showQQPopup = ref(false);
const showBottomActionPopup = ref(false);
const searchTaskId = ref("");
const jobInfo = ref(); // TODO use this
// const jobInfo = ref({});
const jobResult = ref(); // TODO use this
// const jobResult = ref({ isSuccess: true });

const handleShowTaskPopup = (e) => {
  e.stopPropagation();
  showTaskPopup.value = true;
};

const handleRetry = (e) => {
  e?.stopPropagation();
  jobResult.value = null;
  jobInfo.value = null;
};

const handleReset = (e) => {
  e?.stopPropagation();
  jobResult.value = null;
  jobInfo.value = null;
};

const handleShare = (e) => {
  e?.stopPropagation();
  showBottomActionPopup.value = true;
  return;
};

const handleAddQQ = (e) => {
  e.stopPropagation();
  // window.location.href =
  //   `mqqwpa://im/chat?chat_type=wpa&uin=${QQ_NUMBER}&version=1&src_type=web`;
  showQQPopup.value = true;
};

const beforeRead = (file) => {
  console.log("file==-=-==-=", file);
  const isJpgOrPng =
    file.type === "image/jpg" ||
    file.type === "image/jpeg" ||
    file.type === "image/png";
  if (!isJpgOrPng) {
    showFailToast("You can only upload JPG || PNG file!");
  }
  // const isLt2M = file.size / 1024 / 1024 < 2;
  // if (!isLt2M) {
  //   message.error('Image must smaller than 2MB!');
  // }
  return isJpgOrPng;
};

const afterRead = async (cbData) => {
  const MAXIMUM_PIXELS = 1440000;
  const MAXIMUM_LENGTH = 2048;
  // const MAXIMUM_PIXELS = 122;

  const { file } = cbData;
  console.log("cbData====", cbData);
  console.log("file====", file);
  var image = new Image();
  image.src = cbData.content;
  console.log("image====", image.width);
  showLoadingToast({
    message: "上传中...",
    forbidClick: true,
    duration: 0,
  });

  const uploadedImage = document.getElementById("uploaded-image");
  uploadedImage.src = cbData.content;
  uploadedImage.addEventListener(
    "load",
    async () => {
      console.log("uploadedImage.naturalWidth", uploadedImage.naturalWidth);
      console.log("uploadedImage.naturalHeight", uploadedImage.naturalHeight);
      const naturalWidth = uploadedImage.naturalWidth;
      const naturalHeight = uploadedImage.naturalHeight;
      const calcedWidth =
        naturalWidth *
        Math.sqrt(Math.pow(1200, 2) / naturalWidth / naturalHeight);
      const calcedHeight =
        naturalHeight *
        Math.sqrt(Math.pow(1200, 2) / naturalWidth / naturalHeight);
      console.log("calcedWidth", calcedWidth);
      console.log("calcedHeight", calcedHeight);
      if (!naturalWidth || !naturalHeight) {
        showFailToast({
          message: `读取图片失败`,
          duration: 3000,
        });
        return;
      }
      // if (MAXIMUM_PIXELS / naturalWidth < naturalHeight) {
      //   showFailToast({
      //     message: `上传的图片像素超过最大值:${MAXIMUM_PIXELS}`,
      //     duration: 3000,
      //   });
      //   return;
      // }
      if (calcedWidth > MAXIMUM_LENGTH) {
        showFailToast({
          message: `上传的图片过宽 超过最大值:${MAXIMUM_LENGTH}，请裁剪后再上传`,
          duration: 3000,
        });
        return;
      }
      if (calcedHeight > MAXIMUM_LENGTH) {
        showFailToast({
          message: `上传的图片过高 超过最大值:${MAXIMUM_LENGTH}，请裁剪后再上传`,
          duration: 3000,
        });
        return;
      }
      const res = await ApiUpload.ossUpload(file);
      closeToast();
      if (res.url) {
        console.log("res.url", res.url);
        uploadData.value.originUrl = res.url;
        console.log("uploadData.value.originUrl", uploadData.value.originUrl);
      }
    },
    {
      capture: false, // useCapture
      once: true, // 是否设置单次监听
      passive: false, // 是否让阻止默认行为preventDefault()失效
    }
  );
};

const handleCopyQQ = () => {
  copyToClipboard(QQ_NUMBER);
  showSuccessToast("已复制至剪贴板");
};
const handleCopy = () => {
  copyToClipboard(document.getElementById("copyflag").innerText);
  showSuccessToast("已复制至剪贴板");
};

const handleTrans = async () => {
  console.log(999);
  const temData = uploadData.value;
  temData.imageUrl = temData.originUrl;

  if (!temData.imageUrl) {
    showFailToast({
      message: `请先添加图片`,
      duration: 3000,
    });
    return;
  }
  const res = await ApiOC.postTransImgData(temData);
  if (res.code !== REQUEST_OK) {
    showFailToast({
      message: `创建任务失败`,
      duration: 3000,
    });
    return;
  }
  console.log("res-----", res);
  const { data } = res;
  getTransHistory(data.taskId);
  jobInfo.value = data;
  // queuePosition:19
  // taskId:"471c3e84-4e85-4480-89f2-c1176b1e7531"
  // taskId:"1fd65c20-27a8-474a-a4a9-1dfe58134681"
  // taskId:"b8610166-d418-45ec-8d2c-c1cfd8c0e09d"
  // waitTime:63
};

const loopFetchTransStatus = async (tid) => {
  const taskId = tid || searchTaskId.value;
  if (!taskId) {
    showFailToast({
      message: `请填写任务编号`,
      duration: 3000,
    });
    return;
  }
  const res = await ApiOC.getTransResult({ taskId });
  if (res.code !== REQUEST_OK) {
    clearInterval(timer.value);
    showFailToast({
      message: `查询任务失败`,
      duration: 3000,
    });
    return;
  }
  const { data } = res;
  if (data.status === "Processing") {
    jobInfo.value = data;
    // jobResult.value = null;
  } else {
    data.isSuccess = data.status === "Ok";
    // jobInfo.value = null;
    jobResult.value = data;
    clearInterval(timer.value);
  }
  showTaskPopup.value = false;
  console.log("查询:res-----", res);
};

const getTransHistory = async (taskId) => {
  if (timer.value) {
    clearInterval(timer.value);
  }
  loopFetchTransStatus(taskId);
  timer.value = setInterval(() => {
    loopFetchTransStatus(taskId);
  }, 30000);
};

onMounted(() => {
  // 创建并配置全局分享信息
  // webShare.setGlobalShareInfo({
  //   url: shareInfo.url,
  //   title: shareInfo.title,
  //   desc: shareInfo.desc,
  //   imageUrl: shareInfo.imgUrl
  // })

  webShare.setShareInfo({
    url: shareInfo.url,
    title: shareInfo.title,
    desc: shareInfo.desc,
    imageUrl: shareInfo.imgUrl,
  });
});

// mqqwpa://im/chat?chat_type=wpa&uin=1164724620&version=1&src_type=web
</script>

<style lang="scss">
.trans {
  min-height: 100vh;
  background: #f1d55a;
  .head-img {
    width: 750rpx;
    height: 556rpx;
  }
  .content {
    position: relative;
    top: -88rpx;
    margin: 0 auto;
    width: 690rpx;
    padding: 48rpx 40rpx;
    background: #ffffff;
    box-shadow: 0px 8rpx 40rpx rgba(0, 0, 0, 0.06);
    border-radius: 32rpx;
    .btn {
      margin-top: 28rpx;
      .actionbtn {
        width: 100%;
        height: 96rpx;
        background: #e1833e;
        border-radius: 16rpx;
      }
      .searchentry {
        margin-top: 16rpx;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 24rpx;
        line-height: 36rpx;
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &-stage {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 610rpx;
      height: 458rpx;
      background: rgba(225, 131, 62, 0.05);
      border-radius: 48rpx;
      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &-title {
          font-weight: bold;
          font-size: 48rpx;
          line-height: 64rpx;
          color: #e1833e;
        }
        &-time {
          margin-top: 16rpx;
          font-weight: 400;
          font-size: 28rpx;
          line-height: 44rpx;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    &-id {
      margin-top: 32rpx;
      &-title {
        font-weight: bold;
        font-size: 40rpx;
        line-height: 52rpx;
        color: #000000;
      }
      &-value {
        margin-top: 16rpx;
        box-sizing: border-box;
        width: 610rpx;
        height: 96rpx;
        border: 2rpx solid rgba(0, 0, 0, 0.1);
        border-radius: 16rpx;
        font-weight: 900;
        //font-size: 32rpx;
        font-size: 28rpx;
        line-height: 96rpx;
        color: #000000;
        word-break: break-all;
        text-align: center;
      }
    }
    &-copybtn {
      margin-top: 32rpx;
      .actionbtn {
        width: 100%;
        height: 96rpx;
        background: #e1833e;
        border-radius: 16rpx;
      }
    }

    &-result {
      &-title--success,
      &-title--failed {
        font-weight: bold;
        font-size: 64rpx;
        line-height: 80rpx;
      }
      &-title--failed {
        color: #d94a4e;
      }
      &-title--success {
        color: #e1833e;
      }
      &-taskid {
        margin-top: 16rpx;
        font-weight: 400;
        font-size: 28rpx;
        line-height: 44rpx;
        color: rgba(0, 0, 0, 0.5);
        &-value {
          font-weight: 900;
          font-size: 32rpx;
          line-height: 48rpx;
          color: #000000;
        }
      }
      &-successtips {
        margin-top: 16rpx;
        font-weight: 400;
        font-size: 28rpx;
        line-height: 44rpx;
        color: #d94a4e;
      }
      &-failedtips {
        margin-top: 16rpx;
        font-weight: 400;
        font-size: 28rpx;
        line-height: 44rpx;
        color: rgba(0, 0, 0, 0.5);
      }
      .retry-btn {
        margin-top: 32rpx;
        width: 610rpx;
        height: 96rpx;
        background: #e1833e;
        border-radius: 16rpx;
      }
      .imglist {
        &-item {
          margin-top: 16rpx;
          &-title {
            display: flex;
            align-items: flex-end;
            &-main {
              font-weight: bold;
              font-size: 40rpx;
              //line-height:52rpx;
              color: #000000;
            }
            &-sub {
              position: relative;
              bottom: 5rpx;
              margin-left: 8rpx;
              display: flex;
              align-items: flex-end;
              font-weight: 400;
              font-size: 24rpx;
              //line-height:52rpx;
              color: rgba(0, 0, 0, 0.5);
            }
          }
          &-img {
            margin-top: 16rpx;
            width: 610rpx;
            background: #f8f8f8;
            border-radius: 48rpx;
            overflow: hidden;
          }
        }
      }
      .share-btn {
        margin-top: 32rpx;
        width: 610rpx;
        height: 96rpx;
        background: #e1833e;
        border-radius: 16rpx;
      }
    }
  }
  .uploadwrp {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 610rpx;
    min-height: 262rpx;
    background: rgba(225, 131, 62, 0.05);
    border-radius: 48rpx;
    overflow: hidden;
    .info {
      text-align: center;
      &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 48rpx;
        line-height: 64rpx;
        color: #e1833e;
        span {
          margin-left: 10rpx;
        }
      }
      &-desc {
        margin-top: 16rpx;
        font-weight: 400;
        font-size: 28rpx;
        line-height: 44rpx;
        color: rgba(0, 0, 0, 0.5);
      }
    }
    .origin-img {
      width: 610rpx;
      height: auto;
    }
  }
  .prompt {
    margin-top: 32rpx;
    &-title {
      font-weight: bold;
      font-size: 40rpx;
      line-height: 52rpx;
      color: #000000;
    }
    &-content {
      position: relative;
      margin-top: 16rpx;
      &-textarea {
        padding: 24rpx 20rpx 1em 24rpx;
        box-sizing: border-box;
        width: 610rpx;
        border: 2rpx solid rgba(0, 0, 0, 0.1);
        border-radius: 16rpx;
        font-weight: bold;
        font-size: 32rpx;
        line-height: 48rpx;
        color: #000000;
      }
      &-textarea::placeholder {
        font-weight: 400;
        font-size: 32rpx;
        line-height: 48rpx;
        color: rgba(0, 0, 0, 0.5);
      }
      &-counter {
        position: absolute;
        right: 20rpx;
        bottom: 24rpx;
        font-weight: 400;
        font-size: 24rpx;
        line-height: 36rpx;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
.tpopup {
  .van-popup {
    padding: 60rpx 30rpx;
    width: 670rpx;
    background: #ffffff;
    box-shadow: 0 8rpx 40rpx rgba(0, 0, 0, 0.06);
    border-radius: 16rpx;
  }
  &-title {
    font-weight: bold;
    font-size: 40rpx;
    line-height: 52rpx;
    color: #000000;
    font-family: "PingFang SC";
  }
  &-qqnumber {
    margin-top: 12rpx;
    font-size: 24rpx;
    color: #000000;
    font-family: "PingFang SC";
  }
  &-input {
    margin-top: 24rpx;
    box-sizing: border-box;
    width: 610rpx;
    height: 96rpx;
    border: 2rpx solid rgba(0, 0, 0, 0.1);
    border-radius: 16rpx;
    font-weight: 900;
    font-size: 32rpx;
    line-height: 96rpx;
    color: #000000;
    text-align: center;
  }
  &-actbtn {
    margin-top: 24rpx;
    width: 610rpx;
    height: 96rpx;
    background: #e1833e;
    border-radius: 16rpx;
  }
}
.tpopup-poster {
  position: relative;
  top: -80rpx;
  .van-popup {
    padding: 0;
    width: 670rpx;
    //max-height: 70vh;
    overflow-x: hidden;
    background: transparent;
  }
  .van-popup__close-icon {
    position: absolute;
    right: 0;
  }
  //.van-popup__close-icon {
  //  position: absolute;
  //  right: -18rpx;
  //  top: -18rpx;
  //}
}
.poster-img {
  width: 100%;
  padding-top: 80rpx;
  .poster-img-content {
    width: 100%;
    min-height: 70vh;
    max-height: 70vh;
    overflow-y: scroll;
    border-radius: 8rpx;
    img {
      display: inline-block;
      vertical-align: bottom;
      border-radius: 8rpx;
    }
  }
}
.poster-img-tips {
  margin-top: 12rpx;
  font-size: 32rpx;
  text-align: center;
  color: white;
  font-weight: bold;
}
.addicon {
  font-size: 48rpx;
}
.copyqq-btn {
  margin-top: 16rpx;
  box-sizing: border-box;
  width: 610rpx;
  height: 96rpx;
  border: 2rpx solid #e1833e;
  border-radius: 16rpx;
  font-size: 32rpx;
  line-height: 48rpx;
  color: #e1833e;
  font-family: "PingFang SC";
}
.reset-btn {
  margin-top: 16rpx;
  width: 610rpx;
  height: 96rpx;
  background: #e1833e;
  border-radius: 16rpx;
}
.addqq-btn {
  margin-top: 16rpx;
  box-sizing: border-box;
  width: 610rpx;
  height: 96rpx;
  border: 2rpx solid #e1833e;
  border-radius: 16rpx;
  font-size: 32rpx;
  line-height: 48rpx;
  color: #e1833e;
  font-family: "PingFang SC";
}
.top-gap {
  margin-top: 16rpx;
}
.righticon {
  transform: scale(0.8);
  transform-origin: center;
  font-size: 16rpx;
}
</style>

/**
 * Created by Terence on 2022/8/23 - 17:24
 * Description :
 */
import {
  shouldRefreshToken,
  getRefreshToken,
  getStorageToken,
  setRefreshToken,
  setStorageToken,
  setExpireTimestamp,
  removeRefreshToken,
  removeStorageToken,
} from "@/utils/authorize";
import _axios from "axios";
import { showFailToast } from "vant";
import HOSTS from "@/http/hosts";

let refreshPromise: any = null;
const RefreshTokenAxios = createRefreshTokenAxios();

const allowAclList = ["/signin/action/send-verify-code", "/signin/login/phone"];
const checkNeedDeny = (response: any) => {
  if (allowAclList.includes(response.config.url)) {
    return false;
  }
  return true;
};

export const commonResponseInterceptor = (response: any, promise: any) => {
  console.log("response=------", response);
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  // console.log("response!", response);
  if (response.data.code === -10000) {
    setTimeout(() => {
      window.location.href = `${location.origin}/real-name-auth`;
    }, 300);
    return response.data;
  }

  if (response.data.code !== 0 && checkNeedDeny(response)) {
    console.log("errorMsg", response?.data?.message);
    showFailToast(`${response?.data?.message}`);
  }
  if (allowAclList.includes(response.config.url)) {
    return response;
  }
  return response.data;
};

export const commonRequestInterceptor = async (
  request: any,
  headers = {},
  config: any = { ignoreRefresh: false }
) => {
  if (shouldRefreshToken() && !config.ignoreRefresh) {
    if (refreshPromise) {
      try {
        await refreshPromise;
      } catch (e) {
        console.log("refreshTokenError_skip", e);
      }
    } else {
      const refreshToken = getRefreshToken();
      const accessToken = getStorageToken();

      if (refreshToken && accessToken) {
        refreshPromise = refreshLogin({
          refreshToken,
          accessToken,
        });

        try {
          await refreshPromise;

          refreshPromise = null;
        } catch (e) {
          console.log("refreshTokenError", e);
        }
      }
    }
  }
  const token = getStorageToken();

  // 只要浏览过站点任何页面, 就会shouldRefresh
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  request.headers = {
    ...request.headers,
    ...headers,
  };

  // request.headers["x-request-package-id"] = 1045;
  request.headers["x-request-package-id"] = 1048;

  return request;
};

export function createRefreshTokenAxios() {
  const tempConfig = {
    baseURL: HOSTS.api,
    showError: true,
  };
  const RefreshTokenAxios = _axios.create(tempConfig);

  RefreshTokenAxios.interceptors.request.use((request) =>
    commonRequestInterceptor(request, {}, { ignoreRefresh: true })
  );

  RefreshTokenAxios.interceptors.response.use((response) => {
    return response.data;
  });

  return RefreshTokenAxios;
}

function refreshLogin({
  refreshToken,
  accessToken,
}: {
  refreshToken: string;
  accessToken: string;
}) {
  return RefreshTokenAxios.post("/signin/login/refresh", {
    refreshToken,
    accessToken,
  })
    .then(({ data }) => {
      setRefreshToken(data.refreshToken);
      setStorageToken(data.accessToken);
      setExpireTimestamp(data.expiresIn * 1000);
    })
    .catch(() => {
      removeRefreshToken();
      removeStorageToken();
    });
}

/**
 * Created by Terence on 2022/8/23 - 17:39
 * Description :
 */
import { rpTrade, rpNft, rpOcNft } from "@/http";

export default {
  postTransImgData(params: any) {
    // return rpNft.post("/aigc/oc-to-real-world", params);
    return rpOcNft.post("/nft-web/aigc/oc-to-real-world", params);
  },
  getTransResult(params: any) {
    // return rpNft.post("/aigc/oc-to-real-world", params);
    return rpOcNft.post("/nft-web/aigc/get-oc-to-real-world-result", params);
  },
};

/**
 * Created by Terence on 2022/8/23 - 17:32
 * Description :
 */
import { createService } from "./service";
import hosts from "./hosts";

export const rpTrade = createService({
  baseURL: hosts.trade,
});

export const rpNft = createService({
  baseURL: hosts.nft,
});

export const rpApi = createService({
  baseURL: hosts.api,
});

export const rpOcNft = createService({
  baseURL: hosts.ocNft,
});
